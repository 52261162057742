.ProjectNameColumn{
    overflow: hidden;
}
.ProjectNameHeader {
    text-align: center;
}

.ProjectNameHeader h1{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ProjectNameHeader a {
    width: 100%;
}

.ManageProjectButton {
    height: 30px !important;
}

.ManageProjectButton a {
    color: white;
    text-decoration: none;
}

.NavActions>div {
    margin: 0;
    width: 100%;
}

.UserImage {
    width: 40px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
}

.UserImageAdmin {
    width: 40px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}