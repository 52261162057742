body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: ghostwhite;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#create_form{
  display: inline-grid;
  width: 40%;
}

.HelpTooltip {
    margin: 0 8px 0 0 !important;
    vertical-align: top !important;
    width: 15px !important;
    bottom: 5px !important;
}

.HelpTooltip > svg {
    transform: translateY(-50%) translateX(-50%) scale(0.5) !important;
    color: #2b8fbd;
}