.create_form {
    text-align: center;
    text-align: -moz-center;
}

.box-title {
    padding: 10px !important;
}

.box-header {
    padding: 5px !important;
}

.ImportWarningAlert span {
    color: orange;
}

.AddPatternLink {
    text-align: right;
}