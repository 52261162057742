.progressbar {
    padding: 0;
}
.progressbar li {
    list-style-type: none;
    width: 25%;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #232f3e;
}
.progressbar li:before {
    width: 2vh;
    height: 2vh;
    content: '';
    line-height: 2vh;
    border: 2px solid #00a8e1;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
}
.progressbar li:after {
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    border-top: #e7e9e9 dotted thick;
    top: 1vh;
    left: -50%;
    z-index: -1;
}
.progressbar li:first-child:after {
    content: none;
}
.progressbar li.active {
    color: #232f3e;
}
.progressbar li.active:before {
    border-color: #00a8e1;
    background-color: #b9e8f7;
}
.progressbar li.active:after{
    background-color: #b9e8f7;
    border: none;
}

.progressbar li.inprogress:before{
    border-color: #00a8e1;
    background-color: #ffffff;
}
.progressbar li.inprogress:after{
    background-color: #b9e8f7;
    border: none;
}

.workflow {
    display: block;
}