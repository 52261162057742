.ArrowLeft {
    color: #494949;
    position: absolute;
    z-index: 99;
    top: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.ArrowRight {
    color: #494949;
    position: absolute;
    z-index: 99;
    top: 50%;
    transform: translate(-30%, -50%);
    cursor: pointer;
}

.documentList {
    z-index: 99;
    position: relative;
}

.documentList nav {
    height: calc(100vh - 42px);
    overflow-y: initial;
}

.documentList ul {
    overflow-y: auto;
    overflow-x: hidden;
}

.Check {
    color: green;
    position: absolute;
}

.Check::after {
    content: " ";
}

.UploadButton {
    background-color: #4D90FE;
    border: 1px solid #3079ED;
    border-radius: 4px;
    padding: 10px;
    margin: 10px 5px;
    font-size: 12px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    color: #fff;
    white-space: nowrap;
    display: block;
    text-align: center;
    cursor: pointer;
}

#upload_files {
    bottom: 0%;
    position: absolute;
    width: 100%;
}

.DocumentStateCompleted {
    filter: invert(42%) sepia(93%) saturate(1352%) hue-rotate(87deg) brightness(119%) contrast(119%);
}

.DocumentStateUploaded, .DocumentStateParsed, .DocumentStateRegistered {
    filter: invert(100%);
}

.DocumentStateFailedParsed {
    filter: contrast(1) brightness(1) saturate(10);
}