.UserAnnotationSelection {
    background-color: #EFEF1B;
    display: inline;
    cursor: pointer;
}

.PredictionSelection {
    background-color: #ADFF88;
    display: inline;
    cursor: pointer;
}

.MarkDocumentCompleted {
    text-align: center;
    padding-bottom:20px;
}

.documentBox {
    overflow: auto !important;
    min-width: 400px;
    max-height: 100%;
}

.metadataBox {
    overflow: unset !important;
    min-width: 100px;
    max-height: 100%;
    margin-top: 40px;
}

.project {
    display: flex;
}

@media screen and (max-width : 1280px)
{
    .project {
        font-size: 2.2vh;
    }
}

.projectRow {
    height: calc(100vh - 70px);
    flex: 1;
}

#documentContent {
    margin: 40px;
    white-space: pre-wrap;
}