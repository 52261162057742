.MetadataFields {
    z-index: 1;
    position: fixed;
    width: 15%;
    border: 5px solid #232f3e;
    display:none;
}

.MetadataFields p {
    margin-top: 0;
    margin-bottom: 0;
    background-color: #A9A9A9;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}