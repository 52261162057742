.DataLensLogo img {
    width: 150px;
    margin-left: 10px;
    margin-top: 10px;
}

.Home header>nav {
    background-color: #232f3e;
    border: 1px solid black;
    height: 40px;
}

#home_content {
    display: inline-block;
    margin: 0 auto;
    max-width: 100%;
}

.Clickable {
    text-decoration: none;
    color: blue;
    font-weight: bold;
    cursor: pointer;
}

.ExportMetadata {
    cursor: pointer;
    text-align: center;
}

.home {
    max-width: 80%;
    margin: 0 auto;
    width: 60%;
}

#create_project {
    width: 50%;
    position: absolute;
    right: 5%;
    float: right;
}

.NotificationBar{
    position: fixed;
    right: 0;
    z-index: 999;
    max-width: 20%;
    margin: 5px;
}
