.Metadata  {
    height: 95%;
    text-align: justify;
}

#Metadata right_panel {
    position: absolute;
    width: 96px;
    padding-left: 4px;
    height: 100%;
    right: 0;
    background-color: #f0f0ff;
}

#Metadata right_panel:after {
    content: " ";
    background-color: #ccc;
    position: absolute;
    left: 0;
    width: 4px;
    height: 100%;
    cursor: w-resize;
}

.Metadata h1 {
    margin: 0;
}

.Metadata li {
    display: list-item;
    list-style: disc;
    text-align: left;
    cursor: pointer;
}

.OptionContainer {
    margin-top: 10px;
}

.Meta_contents {
    position: relative;
    overflow: hidden;
    max-height: calc(100% - 50px);
    min-height: 90%;
}

.Meta_contents h4 {
    margin: 5px 0px !important;
}


.Loader {
    position: absolute;
    top: 25%;
    left: 45%;
}

.MetadataHeader {
    position: relative;
}

.DataSourceSelect {
    right: 0;
    top: 0;
    text-align: right;
    position: absolute;
    max-width: 50%;
}

.MetadataPopoverHelp > svg {
    transform: translateY(-50%) translateX(-50%) scale(0.5) !important;
    color: #2b8fbd;
}

.MetadataPopoverHelp {
    width: 16px !important;
}

.MetaContentBox h4 {
    margin-bottom: 5px
}

.MetaContentBox ul {
    margin-top: 0px
}

.MetaContentBox {
    overflow: auto !important;
    background-color: #F4F4F4 !important;
}

.ImportedLabelText .ImportLabelHelpIcon {
    margin-right: 0 !important;
}

ul ul {
    padding-left: 20px;
}

.AutoAnnotatedSection {
    overflow: auto !important;
}