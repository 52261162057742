.ProjectHome {
    display: flex;
}

.MetricsBox {
    overflow: auto !important;
}

.AnnotationSummaryBox {
    overflow: auto !important;
}

.TrainingStatus {
    overflow: auto;
}

.ProjectHomeLink {
    color: #077398;
    text-decoration: none;
}

.DocumentHeader>div {
    margin: 0 !important;
}

.MetricsText {
	margin-left: 10px !important;
	margin-top: 10px !important;
	margin-bottom: 20px !important;
}

.NavigationButtonText {
    color: #111;
    text-decoration: none;
}

.NavigationButton {
    background-color: #7bca4a !important;
}